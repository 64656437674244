import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "../CSS/ModalStyles.css";

const CustomAlertV2 = ({
  message,
  onClose,
  onConfirm,
  isCancelButton = true,
}) => {
  const navigate = useNavigate();
  const modalRef = useRef(null);

  const handleConfirm = () => {
    if (onConfirm) onConfirm();
    onClose();
  };

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.focus();
    }
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div className="modal-overlay">
      <div
        className="modal fade show d-block"
        role="dialog"
        aria-modal="true"
        aria-labelledby="alert-title"
        aria-describedby="alert-description"
        tabIndex="-1"
        ref={modalRef}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              {/* <div className="text-start">
                <span className="modal-title" id="alert-title">
                  Alert
                </span>
              </div> */}
            </div>
            <div className="modal-body text-center" id="alert-description">
              <p>{message}</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="okBtn" onClick={handleConfirm}>
                Ok
              </button>
              {isCancelButton && (
                <button type="button" className="CancelBtn" onClick={onClose}>
                  Cancel
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomAlertV2;
