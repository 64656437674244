import React, { useState, useEffect } from "react";
import { Form, Button, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { getDepartmentList } from "../infrastructure/apiService";
import { getHospitalList } from "../infrastructure/apiService";
import { getHospitalDepartmentReport } from "../infrastructure/apiService";
import * as XLSX from "xlsx";
import CustomAlertV2 from "../Component/CustomAlertV2";

const ProcessingReport = () => {
  const [hospitals, setHospitals] = useState([]);
  const [selectedHospital, setselectedHospital] = useState(0);
  const [selectedDepartment, setselectedDepartment] = useState("");
  const [departments, setDepartments] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [loading, setLoading] = useState(false);

  const [successMessage, setSuccessMessage] = useState(null);

  const validate = () => {
    debugger;
    if (
      selectedHospital === 0 ||
      selectedHospital === "" ||
      selectedHospital === "0"
    ) {
      setSuccessMessage("Please select hospital");
      return false;
    } else if (
      selectedDepartment === 0 ||
      selectedDepartment === "" ||
      selectedDepartment === "0"
    ) {
      setSuccessMessage("Please select department");
      return false;
    } else if (!fromDate) {
      setSuccessMessage("Please select 'From date'");
      return false;
    } else if (!toDate) {
      setSuccessMessage("Please select 'To date");
      return false;
    } else if (new Date(fromDate) > new Date(toDate)) {
      setSuccessMessage("'From Date' is greater than 'To Date'");
      return false;
    } else return true;
  };
  const handleGenerateReport = async () => {
    setLoading(true);
    try {
      if (validate()) {
        const response = await getHospitalDepartmentReport(
          selectedHospital,
          selectedDepartment,
          fromDate,
          toDate
        );
        if (response !== null && response.length > 0) {
          exportToExcel(response);
        } else if (response.length === 0) {
          setSuccessMessage("No data found for selected date range.");
        } else {
          setSuccessMessage("Something went wrong");
        }
      }
    } catch (error) {
    } finally {
      debugger;
      setLoading(false);
    }
  };

  const getDepartments = async (hospitalId) => {
    debugger;
    const response = await getDepartmentList(hospitalId);
    setDepartments(response);
  };

  function toUpperCamelCase(text) {
    return text.replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
  }

  const formatDate = (date) => {
    if (!date) return "";
    const [year, month, day] = date.split("-");
    return `${day}-${month}-${year}`;
  };

  function exportToExcel(data) {
    console.log(data);
    // Convert the array of objects to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Get the headers
    const headers = Object.keys(data[0]);

    // Set headers to upper camel case (e.g., FirstName) and bold
    headers.forEach((header, index) => {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: index });
      console.log(header);
      if (header !== "nhsNumber" && header !== "fileId") {
        worksheet[cellAddress].v = toUpperCamelCase(header); // Update header text
      } else if (header === "fileId") {
        worksheet[cellAddress].v = "FileID";
      } else {
        worksheet[cellAddress].v = "NHSNumber";
      }
      if (!worksheet[cellAddress].s) worksheet[cellAddress].s = {}; // Ensure style object exists
      worksheet[cellAddress].s.font = { bold: true }; // Apply bold to font
    });

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate Excel file and download it
    var hospitalName = hospitals.find((x) => x.id === selectedHospital).name;
    var departmentname = departments.find(
      (x) => x.id === selectedDepartment
    ).name;
    console.log(hospitalName);
    console.log(departmentname);
    console.log(fromDate);
    console.log(toDate);
    XLSX.writeFile(
      workbook,
      hospitalName +
        "_" +
        departmentname +
        "_" +
        formatDate(fromDate) +
        "_" +
        formatDate(toDate) +
        ".xlsx"
    );
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getHospitalList();
        setHospitals(response);
      } catch (error) {
      } finally {
      }
    };

    fetchData();
  }, []);

  return (
    <Container>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header">
              <h2>Download Report</h2>
            </div>
            <div className="card-body">
              <Form>
                <div className="row">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <Form.Group controlId="hospital">
                        <Form.Label>Hospital</Form.Label>
                        <Form.Select
                          value={selectedHospital}
                          onChange={(e) => {
                            debugger;
                            setselectedHospital(e.target.value);
                            getDepartments(e.target.value);
                          }}
                        >
                          {hospitals.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                          {/* Add more options as needed */}
                        </Form.Select>
                      </Form.Group>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <Form.Group controlId="department">
                        <Form.Label>Department</Form.Label>
                        <Form.Select
                          value={selectedDepartment}
                          onChange={(e) =>
                            setselectedDepartment(e.target.value)
                          }
                        >
                          {departments.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                          {/* Add more options as needed */}
                        </Form.Select>
                      </Form.Group>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <Form.Group controlId="fromDate">
                        <Form.Label>Processed From Date</Form.Label>
                        <Form.Control
                          type="date"
                          value={fromDate}
                          onChange={(e) => setFromDate(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <Form.Group controlId="toDate">
                        <Form.Label>Processed To Date</Form.Label>
                        <Form.Control
                          type="date"
                          value={toDate}
                          onChange={(e) => setToDate(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <Button
                      variant="primary"
                      onClick={handleGenerateReport}
                      disabled={loading}
                      //   !selectedDepartment ||
                      //   !fromDate ||
                      //   !toDate
                      // }
                    >
                      {loading ? "Pending..." : "Generate"}
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
          {successMessage && (
            <CustomAlertV2
              message={successMessage}
              onClose={() => setSuccessMessage(null)}
              isCancelButton={false}
            />
          )}
        </div>
      </div>
    </Container>
  );
};

export default ProcessingReport;
