// VerticalMenu.js
import React from 'react';

const VerticalMenu = () => {
    return (
        <div className="vertical-menu">
            <div href="#Processing Report" className="menu-item">DScribe Report Generator</div>

        </div>
    );
};

export default VerticalMenu;
